import React, { Component } from "react";
import "./ToolbarActions.scss";

class Toolbar extends Component {
  render() {
    const { config } = this.props;
    return (
      <div className="toolbar-actions">
      </div>
    );
  }
}

export default Toolbar;
