import React from "react";
import Helmet from "react-helmet";
import { Card, CardText, Button } from "react-md";
import "font-awesome/scss/font-awesome.scss";
import Navigation from "../components/Navigation";
import Header from "../components/Header";
import config from "../../data/SiteConfig";
import "./index.scss";
import "./global.scss";
import { loadReCaptcha } from 'react-recaptcha-v3'

const { GATSBY_RECAPTCHA_SITE_KEY } = process.env;

export default class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: true };
  }

  componentDidMount() {
    loadReCaptcha(GATSBY_RECAPTCHA_SITE_KEY);
  }

  navigateBack = (event) => {
    history.back();
    this.setState({ value: history.state === null ? false : true });
  };

  render() {
    const { children } = this.props;
    return (
      <div>
        <Navigation config={config} LocalTitle={this.props.title}>
          <div>
            <Helmet>
              <meta name="description" content={config.siteDescription} />
              <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
            </Helmet>
            {children}
          </div>
        </Navigation>
        <Button
          floating
          fixed
          secondary
          iconClassName="fa fa-arrow-left"
          fixedPosition="bl"
          className="back-button md-drawer-relative"
          onClick={this.navigateBack}
          disabled={!this.state.value}
        />
      </div>
    );
  }
}
