const config = {
  siteTitle: "Play Piano Music", // Site title.
  siteTitleShort: "Play Piano", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Play Piano Music", // Alternative site title for SEO.
  siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteUrl: "https://www.playpianomusic.com", // Domain of your website without pathPrefix.
  pathPrefix: "", // Prefixes all links. For cases when deployed to example.github.io/gatsby-material-starter/.
  fixedFooter: false, // Whether the footer component is fixed, i.e. always visible
  siteDescription: "Learn the basics of playing piano and reading piano sheet music", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteFBAppID: "", // FB Application ID for using app insights
  siteGATrackingID: "UA-17716039-1", // Tracking code ID for google analytics.
  disqusShortname: "", // Disqus shortname.
  postDefaultCategoryID: "Learn Piano Basics", // Default category for posts.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  userName: "Brad", // Username to display in the author segment.
  userEmail: "brad@playpianomusic.com", // Email used for RSS feed's author segment
  userTwitter: "", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "USA", // User location to display in the author segment.
  userAvatar: "", // User avatar to display in the author segment.
  userDescription: "", // User description to display in the author segment.
  copyright: "Copyright © 2019. Play Piano Music", // Copyright string for the footer of the website and RSS feed.
  mainNavLinks: [
    {
      label: 'Learn Piano Basics',
      url: '/learn-piano-basics',
      iconClassName: "fa fa-graduation-cap"
    },
    {
      label: 'Music Theory for Piano',
      url: '/music-theory-for-piano',
      iconClassName: "fa fa-music"
    },
    {
      label: 'Piano Course Reviews',
      url: '/piano-course-reviews',
      iconClassName: "fa fa-search"
    },
  ],
  secondaryNavLinks: [
    {
      label: 'About us',
      url: '/about-us',
      iconClassName: "fa fa-user"
    },
    {
      label: 'Contact us',
      url: '/contact-us',
      iconClassName: "fa fa-envelope"
    },
    {
      label: 'Disclosure',
      url: '/disclosure-statement',
      iconClassName: "fa fa-info"
    },
    {
      label: 'Privacy',
      url: '/privacy-policy',
      iconClassName: "fa fa-shield"
    },
    {
      label: 'Resources',
      url: '/resources',
      iconClassName: "fa fa-binoculars"
    },
  ],
  redirects: [
    {
      localName: '/go/virtualsheetmusic',
      url: 'https://www.virtualsheetmusic.com/piano/?af=pimedia',
      gtagEvent: ''
    },
    {
      localName: '/go/learnmasterpiano',
      url: 'https://shareasale.com/r.cfm?b=175447&u=454943&m=22286&urllink=&afftrack=',
      gtagEvent: ''
    },
    {
      localName: '/go/pianoforall',
      url: 'https://87c75jpig2l52z7jkw19d7sg88.hop.clickbank.net/',
      gtagEvent: ''
    },
    {
      localName: '/go/rocketpiano',
      url: 'https://f80a2dmfj8rx2z8ltei80z5p8v.hop.clickbank.net/',
      gtagEvent: ''
    },
    {
      localName: '/go/sheetmusicplus',
      url: 'https://www.sheetmusicplus.com/?aff_id=657951',
      gtagEvent: ''
    },
    {
      localName: '/go/download-fur-elise-vsm',
      url: 'https://www.virtualsheetmusic.com/score/Eliza.html?af=pimedia',
      gtagEvent: ''
    },
  ],
  affiliates: [
    {
      name: 'Virtual Sheet Music',
      url: 'https://affiliates.virtualsheetmusic.com'
    },
    {
      name: 'Learn and Master Piano',
      url: 'https://shareasale.com'
    },
    {
      name: 'Rocket Piano',
      url: 'https://www.rocketpiano.com/affiliates/affiliates.php'
    },
    {
      name: 'Piano For All',
      url: 'https://pianoforall.com/affiliate-program'
    },
    {
      name: 'ClickBank',
      url: 'https://pimedia.accounts.clickbank.com/account/mainMenu.htm'
    },

  ]
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
